<template>
    <div class="section index-section" key="speedAccusationLocation">
        <div class="section-title">
            <h3>Gut, wo waren Sie unterwegs?</h3>
            <p>Bitte prüfen Sie den Bescheid: Sind Sie innerhalb oder außerhalb geschlossener Ortschaften geblitzt worden?</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.speedAccusationLocation" name="radio-3" value="Innerorts" id="question-5-1">
            <label for="question-5-1" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-city.svg"></span><span>Innerorts</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.speedAccusationLocation" name="radio-3" value="Ausserorts" id="question-5-2">
            <label for="question-5-2" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-landscape.svg"></span><span>Außerorts</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.speedAccusationLocation" name="radio-3" value="unsicher" id="question-5-3">
            <label for="question-5-3" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-ask-question.svg"></span><span>Weiß nicht genau</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'SpeedAccusationLocation',
  computed: mapState({
    user: (state) => state.user,
  }),
  methods: {
    next() {
      this.$emit('next');
    }
  },
  created() {
    this.user.speedAccusationLocation = '';
  }
}
</script>